<template>
  <p class="ps-1">Device Info</p>
  <b-row>
    <b-col>
      <b-card>
        <p v-if="!this.isSuccess">{{ this.statusMessage }}</p>
        <table class="table text-white" ref="deviceInfo" v-if="this.isSuccess">
          <tbody>
            <tr>
              <td class="py-3">Device ID:</td>
              <td class="py-3">{{ this.deviceInfo.device_id }}</td>
            </tr>
            <tr>
              <td class="py-3">Serial Number:</td>
              <td class="py-3">{{ this.deviceInfo.serial_number }}</td>
            </tr>
            <tr>
              <td class="py-3">Model:</td>
              <td class="py-3">{{ this.deviceInfo.model }}</td>
            </tr>
            <tr v-if="this.deviceInfo.activation_code">
              <td class="py-3">Activation Code:</td>
              <td class="py-3">{{ this.deviceInfo.activation_code }}</td>
            </tr>
            <tr v-if="this.deviceInfo.batch">
              <td class="py-3">Batch ID:</td>
              <td class="py-3">{{ this.deviceInfo.batch }}</td>
            </tr>
            <tr>
              <td class="py-3">Manufactured:</td>
              <td class="py-3">{{ creationMonth(this.deviceInfo.creation_timestamp) }}</td>
            </tr>
            <tr>
              <td class="py-3">Warranty Status:</td>
              <td class="py-3">{{ warrantyStatus(this.deviceInfo.warranty_registration_timestamp) }}</td>
            </tr>
            <tr v-if="this.deviceInfo.customer_name">
              <td class="py-3">Customer:</td>
              <td class="py-3">{{ this.deviceInfo.customer_name }}</td>
            </tr>
            <tr v-if="this.deviceInfo.user_id">
              <td class="py-3">User ID:</td>
              <td class="py-3">{{ this.deviceInfo.user_id }}</td>
            </tr>
          </tbody>
        </table>
      </b-card>
    </b-col>
  </b-row>
  <div v-if="this.isSuccess">
    <b-row class="mt-3" v-if="this.navigator.share">
      <b-col>
        <b-button class="w-100" v-on:click="copyDetails()">{{ this.shareMessage }}</b-button>
      </b-col>
    </b-row>
    <b-row class="mt-3" v-if="this.isAuthenticated() && this.showRegisterWarranty">
      <b-col>
        <b-button v-on:click="registerWarranty()" class="w-100">{{ this.registerWarrantyMessage }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const { backendUrl, isAuthenticated, getAuthHeader } = require('./constants')
const moment = require('moment')
const axios = require('axios')
export default {
  name: 'DeviceInfo',
  components: {
  },
  data() {
    return {
      id: null,
      isSuccess: false,
      showRegisterWarranty: false,
      statusMessage: 'Fetching device details. Please wait...',
      shareMessage: 'Share device details',
      registerWarrantyMessage: 'Register for warranty',
      deviceInfo: {
        device_id: "",
        serial_number: "",
        model: "",
        creation_timestamp: "",
        warranty_registration_timestamp: ""
      },
      isAuthenticated,
      navigator
    }
  },
  methods: {
    creationMonth: (timestamp) => moment(timestamp).format('MMMM YYYY'),
    warrantyStatus(warrantyDate) {
      if (!warrantyDate) {
        this.showRegisterWarranty = true
        return "Not Registered"
      }
      const expiry = moment(warrantyDate).add(1, 'year')
      if (moment().diff(expiry, 'days') < 0) {
        return `Active till ${expiry.format('MMMM DD, YYYY')}`
      } else {
        return `Expired on ${expiry.format('MMMM DD, YYYY')}`
      }
    },
    copyDetails() {
      const data = {
        text: `OpenWire Device Info\n\n${this.$refs.deviceInfo.innerText}`
      }
      navigator.share(data).then(() => {
        this.shareMessage = "Sharing details..."
        setTimeout(() => {
          this.shareMessage = "Share device details"
        }, 2000)
      })
    },
    fetchDeviceInfo() {
      // Fetch data from backend
      axios.get(`${backendUrl}/devices/search`, Object.assign({}, {
        params: {
          keyword: this.id
        }
      }, getAuthHeader()
      )).then((resp) => {
        this.deviceInfo = resp.data.response
        this.showRegisterWarranty = this.deviceInfo.warranty_registration_timestamp ? false : true
        this.isSuccess = true
      }).catch((err) => {
        console.error(err)
        this.isSuccess = false
        this.statusMessage = err.response.data.message || 'Failed to fetch data. Please try again later.'
      })
    },
    registerWarranty() {
      const customer = prompt("Enter name of customer:")
      if (customer != null && customer != "") {
        this.registerWarrantyMessage = "Registering..."
        axios.post(`${backendUrl}/devices/${this.id}/registerWarranty`, null, Object.assign({}, {
          params: {
            customer: customer
          },
        }, getAuthHeader())).then(() => {
          this.registerWarrantyMessage = "Registered"
          this.fetchDeviceInfo()
        }).catch((err) => {
          console.error(err)
          this.registerWarrantyMessage = "Failed to register. Please Try again."
        })
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    } else {
      try {
        const data = JSON.parse(this.$route.query.data)
        this.id = data.device_id || data.serial
        if (!this.id) {
          throw "Invalid QR code"
        }
      } catch {
        console.log("Failed to decode data!")
        this.isSuccess = false
        this.statusMessage = 'Invalid QR Code! Please try again.'
        return
      }
    }
    this.fetchDeviceInfo()
  }
}
</script>

<style>
</style>
