const backendUrl = process.env.BACKEND_URL || 'https://mdm-backend-qm3g.onrender.com/api/v1'

function isAuthenticated() {
    if (localStorage.sessionId && localStorage.sessionPassword)
        return true
    return false
}

function isAdmin() {
    return (localStorage.isAdmin ? localStorage.isAdmin == "true" : false)
}

function getAuthHeader(id, password) {
    const data = {
        id: id || localStorage.sessionId,
        password: password || localStorage.sessionPassword
    }
    return {
        headers: {
            authorization: `Bearer ${JSON.stringify(data)}`
        }
    }
}

function login(id, password, isAdmin) {
    localStorage.sessionId = id
    localStorage.sessionPassword = password
    localStorage.isAdmin = isAdmin
}

function logout() {
    delete localStorage.sessionId
    delete localStorage.sessionPassword
    delete localStorage.isAdmin
}

export {
    backendUrl,
    getAuthHeader,
    isAuthenticated,
    isAdmin,
    login,
    logout
}
