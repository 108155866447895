<template>
  <p class="ps-1">Enroll Device</p>

  <div v-if="!this.errorMessage">
    <!-- Initial state (No data, requesting batch number) -->
    <div v-if="this.progress == this.states.initial">
      <b-form @submit="saveBatch">
        <b-form-group id="batch" label="Batch ID:" label-for="batch">
          <b-form-input id="batch" v-model="batch" type="text" placeholder="Batch ID" required></b-form-input>
        </b-form-group>
        <b-button class="w-100 mt-3" type="submit">Continue</b-button>
      </b-form>
      <p class="mt-3">Batch ID is a unique id used to identify devices manufactured together.</p>
    </div>

    <!-- Final State (All device details are available) -->
    <div v-if="this.progress == this.states.final">
      <b-row>
        <b-col>
          <b-card>
            <ul class="p-0 with-border">
              <li class="row pt-2">
                <p class="col-6">Device ID:</p>
                <p class="col">{{ this.deviceInfo.device_id }}</p>
              </li>
              <li class="row pt-2">
                <p class="col-6">Serial Number:</p>
                <p class="col">{{ this.deviceInfo.serial }}</p>
              </li>
              <li class="row pt-2">
                <p class="col-6">Model:</p>
                <p class="col">{{ this.deviceInfo.model }}</p>
              </li>
              <li class="row pt-2">
                <p class="col-6">Batch:</p>
                <p class="col">{{ this.deviceInfo.batch }}</p>
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button class="w-100" v-on:click="enrollDevice()">{{ this.enrollMessage }}</b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Enrolled State -->
    <div v-if="this.progress == this.states.enrolled">
      <b-row>
        <b-col>
          <b-card>
            <h6>Successfully enrolled device !</h6>
            Activation Code: <br />
            <h3 class="mt-3">{{ this.deviceInfo.activation_code }}</h3>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button class="w-100" v-on:click="viewDevice()">View Device</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button class="w-100" v-on:click="enrollNext()">Enroll Next Device</b-button>
        </b-col>
      </b-row>
    </div>
  </div>

  <!-- If Error -->
  <div v-if="this.errorMessage"> <b-row>
      <b-col>
        <b-card>
          {{ this.errorMessage }}
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const { backendUrl, getAuthHeader } = require('./constants')
const moment = require('moment')
const axios = require('axios')
export default {
  name: 'EnrollDevice',
  components: {
  },
  data() {
    return {
      progress: 1,
      enrollMessage: "Enroll Device",
      errorMessage: null,
      batch: moment().format('YYYYMMDD'),
      states: {
        initial: 1,
        scanSerial: 2,
        scanUUID: 3,
        final: 4,
        enrolled: 5
      },
      deviceInfo: {
        device_id: "",
        serial: "",
        model: "",
        batch: ""
      }
    }
  },
  methods: {
    saveBatch() {
      this.$router.push({
        path: '/scan', query: {
          message: 'Scan QR code from VaporOS',
          callback: 'enrollDevice',
          batch: this.batch,
          data_param: 'device_info',
          should_confirm: true
        }
      })
    },
    validateDeviceQR() {
      try {
        const decodedData = JSON.parse(this.$route.query.device_info)
        if (!(decodedData.serial && decodedData.model)) {
          this.errorMessage = "Device QR code is invalid. Please try again."
          return false
        }
        this.deviceInfo.serial = decodedData.serial
        this.deviceInfo.model = decodedData.model
      } catch (err) {
        console.error(err)
        this.errorMessage = "Failed to decode data. Please try again."
        return false
      }
      return true
    },
    validateBoxQR() {
      try {
        const decodedData = JSON.parse(this.$route.query.box_info)
        if (!decodedData.device_id) {
          this.errorMessage = "Box QR code is invalid. Please try again."
          return false
        }
        this.deviceInfo.device_id = decodedData.device_id
      } catch (err) {
        console.error(err)
        this.errorMessage = "Failed to decode data. Please try again."
        return false
      }
      return true
    },
    enrollDevice() {
      this.enrollMessage = "Enrolling device..."
      const postData = Object.assign({}, this.deviceInfo)
      delete (postData.device_id)
      axios.post(`${backendUrl}/admin/enroll/${this.deviceInfo.device_id}`, postData, getAuthHeader())
        .then((resp) => {
          console.log(resp)
          this.deviceInfo = resp.data.response.device
          this.progress = this.states.enrolled
        }).catch((err) => {
          this.isSuccess = false
          this.errorMessage = err.response.data.message || 'Failed to enroll device. Please try again later.'
          console.error(err)
        })
    },
    viewDevice() {
      this.$router.push({
        path: `/deviceInfo`, query: {
          id: this.deviceInfo.device_id
        }
      })
    },
    enrollNext() {
      this.batch = this.deviceInfo.batch
      this.deviceInfo = {
        device_id: "",
        serial: "",
        model: "",
      }
      this.progress = this.states.initial
      this.$router.push(`/enrollDevice`)
    },
  },
  mounted() {
    if (this.$route.query.box_info) {
      if (this.validateBoxQR() && this.validateDeviceQR()) {
        this.deviceInfo.batch = this.$route.query.batch
        this.progress = this.states.final
      }

    } else if (this.$route.query.device_info) {
      this.progress = this.states.scanUUID
      const query = Object.assign(this.$route.query, {
        message: 'Scan QR code from device package',
        callback: 'enrollDevice',
        data_param: 'box_info',
        should_confirm: true
      })
      this.$router.push({
        path: '/scan', query
      })
    } else {
      this.progress = this.states.initial
    }
  }
}
</script>

<style>
</style>
