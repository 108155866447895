<template>
  <h6 class="mb-3">{{ this.$route.query.message }}</h6>
  <stream-barcode-reader v-if="!this.scanSuccess" @decode="onDecode"></stream-barcode-reader>
  <div v-if="this.scanSuccess">
    <b-row>
      <b-col>
        <b-card>
          <p>Scanned Data:</p>
          <ul class="p-0 with-border">
            <li class="row pt-2" v-for="[key, value] of Object.entries(this.getDecodedData())" v-bind:key="key">
              <p class="col-6">{{ key.replaceAll("_", " ").toLocaleUpperCase() }}:</p>
              <p class="col">{{ value }}</p>
            </li>
          </ul>
        </b-card>
      </b-col>
    </b-row>
    <b-button class="w-100 mt-3" v-on:click="completeScan">Continue</b-button>
  </div>
</template>

<script>
import { StreamBarcodeReader } from 'vue-barcode-reader'
export default {
  name: 'ScanQR',
  components: {
    StreamBarcodeReader
  },
  data() {
    return {
      scanSuccess: false,
      scanResult: null
    }
  },
  methods: {
    onDecode(decodedData) {
      this.scanSuccess = true
      this.scanResult = decodedData
      console.log(`QR Code data: ${decodedData}`)
      if (!this.$route.query.should_confirm) {
        this.completeScan()
      }
    },
    getDecodedData() {
      try {
        const decodedData = JSON.parse(this.scanResult)
        return decodedData
      } catch (err) {
        console.error(err)
      }
      return {}
    },
    completeScan() {
      const dataParam = this.$route.query.data_param || "data"

      // Create copy of query without the parameters used by scan
      let query = Object.assign({}, this.$route.query)
      delete (query["callback"])
      delete (query["message"])
      delete (query["data_param"])

      // Add data to query
      query[dataParam] = this.scanResult

      this.$router.push({ path: `/${this.$route.query.callback}`, query: query })
    }
  }
}
</script>

<style>
.qrcode-stream-camera {
  max-width: 100%;
}
</style>
