import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import App from './App.vue'
import Main from './Main.vue'
import Scan from './Scan.vue'
import DeviceInfo from './DeviceInfo.vue'
import EnrollDevice from './EnrollDevice.vue'
import Login from './Login.vue'
import BootstrapVue from "bootstrap-vue-next"

import 'bootstrap/dist/css/bootstrap.css'
import './registerServiceWorker'

const routes = [
    { path: '/', component: Main },
    { path: '/deviceInfo', component: DeviceInfo },
    { path: '/scan', component: Scan },
    { path: '/enrollDevice', component: EnrollDevice },
    { path: '/login', component: Login }
]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

createApp(App).use(BootstrapVue).use(router).mount('#app')
