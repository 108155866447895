<template>
  <div class="b-flex flex-column">
    <p class="ps-1">Manage Devices</p>
    <b-row class="mb-2">
      <b-col>
        <router-link to="/scan?callback=deviceInfo&message=Scan device QR code"
          style="text-decoration: none; color: inherit;">
          <b-card title="Scan Device">
            <p>Scan and get details of a device</p>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
    <div v-if="showAuthMenu()">
      <p class="mt-3 ps-1">Administration</p>
      <b-row class="mb-2">
        <b-col>
          <router-link to="/enrollDevice" style="text-decoration: none; color: inherit;">
            <b-card title="Enroll Device">
              <p>Enroll a new device into the inventory</p>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { isAuthenticated, isAdmin } from './constants';

export default {
  name: 'MainPage',
  methods: {
    showAuthMenu: isAdmin
  },
  mounted() {
    if (!isAuthenticated())
      this.$router.push('/login')
  }
}
</script>
