<template>
  <BContainer :fluid="true" class="d-flex flex-column" id="app-container">
    <router-link to="/" style="text-decoration: none; color: inherit;">
      <h2 class="pt-3 pb-4">OpenWire</h2>
    </router-link>
    <div class="h-100 pb-3" style="overflow-x:hidden; overflow-y: auto;">
      <RouterView></RouterView>
    </div>
  </BContainer>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#app-container {
  max-height: 100vh;
  overflow: hidden;
}

body {
  color: #fefefe !important;
  background-color: #2f2f2f !important;
}

.card {
  background-color: #464646 !important;
  color: #fefefe !important;
}

.text-muted {
  color: #a4a4a4 !important;
}

ul.with-border>li:not(:last-child) {
  border-bottom: var(--bs-border-width) var(--bs-border-style) #747779 !important;
}
</style>
