<template>
  <b-form @submit="performLogin">
    <b-form-group id="id" label="User ID:" label-for="id">
      <b-form-input id="id" v-model="loginId" type="text" placeholder="User ID" required></b-form-input>
    </b-form-group>
    <b-form-group class="mt-3" id="login" label="Password:" label-for="login">
      <b-form-input id="login" v-model="loginPassword" type="password" placeholder="Password" required></b-form-input>
    </b-form-group>
    <b-button class="w-100 mt-3" type="submit">{{ this.loginMessage }}</b-button>
  </b-form>

  <!-- If Error -->
  <div v-if="this.errorMessage" class="mt-3">
    <b-row>
      <b-col>
        <b-card>
          {{ this.errorMessage }}
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const { login, backendUrl, getAuthHeader, isAuthenticated, logout } = require('./constants')
const axios = require('axios')
export default {
  name: 'LoginPage',
  components: {},
  data() {
    return {
      loginId: "",
      loginPassword: "",
      loginMessage: "Login",
      errorMessage: null
    }
  },
  methods: {
    performLogin() {
      this.loginMessage = "Logging in..."
      axios.get(`${backendUrl}/user/login`, getAuthHeader(this.loginId, this.loginPassword))
        .then((res) => {
          console.log(res)
          login(this.loginId, this.loginPassword, res.data.response.isAdmin)
          this.$router.push('/')
        })
        .catch((err) => {
          this.loginMessage = "Login"
          if (err.response.status == 403) {
            this.errorMessage = "Login Failed. Invalid credentials"
          } else {
            this.errorMessage = err.response
          }
          console.error(err)
        })
    },
    performLogout() {
      logout()
      this.$router.push('/')
    },
  },
  mounted() {
    if (isAuthenticated())
      this.$router.push('/')
  }
}
</script>

<style>
</style>
